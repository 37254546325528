@import '@/styles/artifacts.scss';
@font-face {
  font-family: 'Vodafone';
  font-style: normal;
  font-weight: 100;
  src: url(~@/assets/fonts/vodafone/vodafone-light.eot?#iefix) format('embedded-opentype'),
    url(~@/assets/fonts/vodafone/vodafone-light.woff) format('woff');
}

@font-face {
  font-family: 'Vodafone';
  font-style: normal;
  font-weight: 400;
  src: url(~@/assets/fonts/vodafone/vodafone-regular.eot?#iefix) format('embedded-opentype'),
    url(~@/assets/fonts/vodafone/vodafone-regular.woff) format('woff');
}

@font-face {
  font-family: 'Vodafone';
  font-style: normal;
  font-weight: 700;
  src: url(~@/assets/fonts/vodafone/vodafone-regular-bold.eot?#iefix) format('embedded-opentype'),
    url(~@/assets/fonts/vodafone/vodafone-regular-bold.woff) format('woff');
}
