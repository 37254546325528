@import '@/styles/artifacts.scss';
@import '~normalize.css';
@import './vodafone-font.css';

:root {
  @each $name, $size in $fontSizes {
    --#{$name}: #{$size};
  }
  @each $name, $weight in $fontWeight {
    --fw-#{$name}: #{$weight};
  }
  @each $name, $height in $lineHeights {
    --#{$name}: #{$height};
  }
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Vodafone', sans-serif;
  font-size: fontSize(fs-base);
  line-height: lineHeight(lh-100);
  background-color: colorVodafone(background-main);
}

body {
  overflow-y: scroll;
}
